import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CompetitionBlock from "../components/MainStats/CompetitionBlock"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import SelectMenuNav from "../components/Utilities/SelectMenuNav"

export const query = graphql`
  query($slug: Date!) {
    allSheetMessiIntStats(filter: { year: { eq: $slug } }) {
      edges {
        node {
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
        }
      }
    }
    allSheetRonaldoIntStats(filter: { year: { eq: $slug } }) {
      edges {
        node {
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
        }
      }
    }
  }
`

function IntStatsSeason(props) {

  const path = props.location.pathname;

  const mdata = props.data.allSheetMessiIntStats.edges
  const rdata = props.data.allSheetRonaldoIntStats.edges

  const year = mdata[0].node.year

  return (
    <Layout>
      <SEO
        title={`${year} International Goals and Stats - Messi Argentina Stats and Ronaldo Portugal Stats`}
        description={`Who scored more international goals in ${year}? Compare Messi vs Ronaldo stats on the international stage in ${year}.`}
        canonicalPath={`${path}/`}
      />

      <h1 className="sr-only">{year} <span>International Goals and Stats - Messi vs Ronaldo</span></h1>

      <SelectMenuNav
        current={mdata[0].node.year}
        parentPath="international-stats"
      />
      

      <CompetitionBlock competition="All Internationals" type="int" mdata={mdata} rdata={rdata} />

      <CompetitionBlock competition="Competitive Internationals" type="int" mdata={mdata} rdata={rdata} />

      <CompetitionBlock competition="International Friendlies" type="int" mdata={mdata} rdata={rdata} />

      {mdata.filter(d => d.node.competition === "World Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "World Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="World Cup" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Copa America / Euros").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Copa America / Euros").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Copa America / Euros" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "World Cup Qualifiers").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "World Cup Qualifiers").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="World Cup Qualifiers" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Euro Qualifiers").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Euro Qualifiers").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Euro Qualifiers" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Confederations Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Confederations Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Confederations Cup" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "UEFA Nations League").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "UEFA Nations League").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="UEFA Nations League" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Finalissima").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Finalissima").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Finalissima" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      <LastUpdated type="International Stats" />
      
    </Layout>
  )
}

export default IntStatsSeason
